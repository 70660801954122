import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

const testings_check_child_routes: IRouteConfig[] = [
  {
    path: "",
    name: "all",
    meta: {
      name: "Все",
      check_jwt: true,
      permissions: [EAdmissionPermission.TESTINGS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/testing_check/All.vue"
      )
  },
  {
    name: "question",
    path: ":question_id",
    props: true,
    meta: {
      name: "Вопросы",
      check_jwt: true,
      permissions: [EAdmissionPermission.TESTINGS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/testing_check/One.vue"
      )
  }
];

const testings_child_routes: IRouteConfig[] = [
  {
    path: "/testings",
    name: "testings",
    props: true,
    meta: {
      name: "Тестирование",
      check_jwt: true,
      permissions: [EAdmissionPermission.TESTINGS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/testings/Testings.vue"
      )
  },
  {
    path: "/testing_checks",
    name: "testing_checks",
    meta: {
      name: "Проверка открытых вопросов",
      check_jwt: true,
      permissions: [EAdmissionPermission.TESTINGS]
    },
    redirect: { name: "all" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/TestingCheck.vue"),
    children: testings_check_child_routes
  }
];

export const testings_routes: IRouteConfig[] = [
  {
    path: "/test",
    name: "test",
    meta: {
      name: "Тесты",
      check_jwt: true,
      permissions: [EAdmissionPermission.TESTINGS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Test.vue"),
    children: testings_child_routes,
    redirect: { name: "testing" }
  }
];
