
import { Component, Vue } from "vue-property-decorator";

import { INavRouteConfig } from "@/router/interface";
import { nav_routes } from "@/router";
import { parseAndCheckNavRoutes } from "@/router/parser";

@Component({ name: "CNavigation" })
export default class Navigation extends Vue {
  public show_menu: boolean = false;
  public show_menu_mobile: boolean = false;

  public links: INavRouteConfig[] = [];

  private watchers: Function[] = [];

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected created() {
    this.links = parseAndCheckNavRoutes(
      nav_routes,
      this.$api.getCurrentUser(),
      this.$api.getCurrentUserPermissions()
    );

    this.show_menu =
      localStorage.menu && this.$store.state.deviceType !== 0
        ? localStorage.menu === "true"
        : false;
    this.$store.commit("showNavMenu", this.show_menu);

    this.watchers.push(
      this.$store.subscribeAction((action, state) => {
        if (action.type === "updateCurrentUser") {
          this.links = parseAndCheckNavRoutes(
            nav_routes,
            state.currentUser,
            state.currentUserPermissions
          );
        }
      })
    );
  }

  public saveMenuPos() {
    this.show_menu = !this.show_menu;

    localStorage.menu = this.show_menu;
    this.$store.commit("showNavMenu", this.show_menu);
  }

  public toggleMobileMenu() {
    this.show_menu_mobile = !this.show_menu_mobile;
  }

  public signOut() {
    this.$api.unauthorize();
  }
}
