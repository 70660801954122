import debounce from "lodash/debounce";

export function pluck<T, K>(array: T[], key: string): K[] {
  // tslint:disable-next-line: no-unsafe-any
  return array.map((a: any) => a[key]);
}

export function shuffle<T>(array: T[]): T[] {
  return array.sort(() => Math.random() - 0.5);
}

export function enumToObjectArray<E>(e: E): Array<{ key: string; value: any }> {
  const res: Array<{ key: string; value: any }> = [];

  const values = Object.values(e);
  const count = values.length / 2;

  for (let i = 0; i < count; i++) {
    res.push({
      key: values[i] as string,
      value: values[count + i]
    });
  }

  return res;
}

export function Debounce(delay: number) {
  return (target: any, prop: string) => {
    return {
      configurable: true,
      enumerable: false,
      value: debounce(target[prop], delay)
    };
  };
}

export function getIconFromUnitableType(unitable_type: string) {
  switch (unitable_type) {
    case "Document::Version": // документ
      return "article";
    case "Test::Version": // тест
      return "test";
    case "Event::Version": // встреча
      return "event";
    case "Practical::Version": // практическое задание
      return "practical";
    case "Program::Inspector":
      return "inspector";
    default:
      return "";
  }
}

export function getTitleFromUnitableType(unitable_type: string) {
  switch (unitable_type) {
    case "Document::Version": // документ
      return "Документ-ссылка на Digital Library";
    case "Test::Version": // тест
      return "Тест";
    case "Event::Version": // встреча
      return "Встреча";
    case "Practical::Version": // практическое задание
      return "Практическое задание";
    case "Program::Inspector":
      return "Проверяющие";
    default:
      return "";
  }
}
