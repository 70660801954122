import { EAdmissionPermissionLeader } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

const teams_child_routes: IRouteConfig[] = [
  {
    path: "",
    name: "team_all",
    meta: {
      name: "Моя команда",
      check_jwt: true,
      permissions: [EAdmissionPermissionLeader.TEAMS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/team/All.vue")
  },
  {
    path: ":user_id",
    name: "teammate",
    props: true,
    meta: {
      name: "Участник",
      check_jwt: true,
      permissions: [EAdmissionPermissionLeader.TEAMS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/team/One.vue")
  },
  {
    path: ":user_id/testing/:id",
    name: "testing_result",
    props: true,
    meta: {
      name: "Результат теста",
      check_jwt: true,
      permissions: [EAdmissionPermissionLeader.TEAMS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/team/testing/Result.vue"
      )
  }
];

export const teams_routes: IRouteConfig[] = [
  {
    path: "/team",
    name: "team",
    meta: {
      name: "Моя команда",
      check_jwt: true,
      permissions: [EAdmissionPermissionLeader.TEAMS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/MyTeam.vue"),
    children: teams_child_routes,
    redirect: { name: "team_all" }
  }
];
