import _Vue from "vue";
import axios, { AxiosRequestConfig, AxiosInstance } from "axios";
import JwtDecode from "jwt-decode";

import store from "@/store";
import router, { routes_meta } from "@/router";

import auth_routes, {
  ISignInResponse,
  ISignInResponsePermissions
} from "./routes/auth";
import { getCookie, setCookie, clearCookie } from "./cookie";
import { IRouteMeta, TAdmissionPermission } from "@/router/interface";
import { pluck } from "@/helpers";

const COOKIE_JWT_TOKEN_KEY = "JWT";
const COOKIE_JWT_EXPIRES_IN_KEY = "expires_in";
const COOKIE_REFRESH_TOKEN_KEY = "refresh_token";
const COOKIE_REFRESH_EXPIRES_IN_KEY = "refresh_token_expires_in";
const COOKIE_IS_USER_DEVELOPER_KEY = "is_user_developer";

export class Api {
  public readonly environment = process.env.VUE_APP_ENVIRONMENT!;

  public readonly regex_url = '^(ftp|http|https):\\/\\/[^ "]+$';
  public readonly api_url = process.env.VUE_APP_API!;
  public readonly cdn_url = process.env.VUE_APP_STATIC_UPLOADER!;

  private readonly routes_without_auth: string[] = [
    "/v1/settings/available_banners",
    "/auth/sign_in",
    "/auth/refresh",
    "/auth/open_id",
    "/auth/login_by_openid"
  ];

  private refresh_token_in_progress: boolean = false;

  private http_client: AxiosInstance;
  private http_client_import: AxiosInstance;

  constructor() {
    this.http_client = axios.create({ baseURL: this.api_url });
    this.http_client_import = axios.create({ baseURL: this.api_url });

    this.useRequestMiddleware();
    this.useResponseMiddleware();
  }

  private useRequestMiddleware() {
    this.http_client.interceptors.request.use(
      async config => {
        let authorization_header: string | null = null;

        if (!this.routes_without_auth.includes(config.url as string)) {
          authorization_header = await this.getJwtToken();
        }

        return {
          ...config,
          ...{
            headers: {
              common: {
                Authorization: authorization_header,
                "X-Authorization": this.currentUserIsDeveloper().toString()
              }
            }
          }
        };
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  private useResponseMiddleware() {
    this.http_client.interceptors.response.use(
      async response => {
        return response;
      },
      async error => {
        if (
          error.message === "Network Error" &&
          error.config.url !== "/healthz"
        ) {
          if (router.currentRoute.name !== "service_unavailable") {
            return this.redirectToServiceUnavailable();
          } else {
            alert("Сервер временно недоступен. Пожалуйста, попробуйте позже.");

            return Promise.reject(error);
          }
        }

        switch (error.response.status) {
          case 302:
            if (error.response.data.ouath_redirection) {
              window.location.href = error.response.data.link;
            }

            break;
          case 401: // jwt отсутствует
          case 419: // refresh_token не валиден
            return this.redirectToAuth();
          case 503:
            return this.redirectToServiceUnavailable();
          default:
            return Promise.reject(error);
        }
      }
    );
  }

  public getCurrentUser(): IJWTUser {
    return store.state.currentUser!;
  }

  public getCurrentUserPermissions(): ISignInResponsePermissions[] {
    return store.state.currentUserPermissions || [];
  }

  public async reLoadCurrentUser() {
    await this.getJwtToken();

    const {
      data: res
    }: {
      data: Pick<ISignInResponse, "user" | "permissions">;
    } = await this.http_client.get(auth_routes.user_data);

    await store.dispatch("updateCurrentUser", {
      authorized: true,
      currentUser: res.user,
      currentUserPermissions: res.permissions
    });

    if (res.user.is_lock) {
      await this.redirectToTechnicalWork();
    }
  }

  public async unauthorize() {
    clearCookie([COOKIE_IS_USER_DEVELOPER_KEY]);

    return this.redirectToAuth();
  }

  public async authorize(login: string, password: string) {
    const { data: res }: { data: ISignInResponse } = await this.post(
      auth_routes.sign_in,
      {
        login,
        password,
        is_dev: true
      }
    );

    if (res.error) {
      throw new Error(res.error);
    }

    this.setCurrentUser(res);

    return res;
  }

  public async authorizeByOpenID(token: string) {
    const { data: res }: { data: ISignInResponse } = await this.post(
      auth_routes.open_id,
      {
        token
      }
    );

    if (res.error) {
      throw new Error(res.error);
    }

    this.setCurrentUser(res);

    return res;
  }

  public async getJwtToken() {
    let jwt_token = getCookie(COOKIE_JWT_TOKEN_KEY);
    const jwt_token_expires_in = getCookie(COOKIE_JWT_EXPIRES_IN_KEY);
    const refresh_token = getCookie(COOKIE_REFRESH_TOKEN_KEY);
    const refresh_token_expires_in = getCookie(COOKIE_REFRESH_EXPIRES_IN_KEY);

    // если JWT отсутствует или истёк, но refresh есть и действителен,
    // то обновляем JWT

    if (
      !jwt_token.length ||
      !jwt_token_expires_in.length ||
      new Date(jwt_token_expires_in) < new Date()
    ) {
      if (
        refresh_token.length &&
        refresh_token_expires_in.length &&
        new Date(refresh_token_expires_in) > new Date()
      ) {
        if (!this.refresh_token_in_progress) {
          await this.authorizationRefresh();
        }

        jwt_token = getCookie(COOKIE_JWT_TOKEN_KEY);
      } else {
        await this.redirectToAuth();
      }
    }

    return jwt_token;
  }

  private setCurrentUser(res: ISignInResponse) {
    setCookie(
      COOKIE_JWT_TOKEN_KEY,
      res.access_token,
      new Date(res.expires_in).toUTCString()
    );
    setCookie(
      COOKIE_JWT_EXPIRES_IN_KEY,
      res.expires_in,
      new Date(res.expires_in).toUTCString()
    );
    setCookie(
      COOKIE_REFRESH_TOKEN_KEY,
      res.refresh_token,
      new Date(res.refresh_token_expires_in).toUTCString()
    );
    setCookie(
      COOKIE_REFRESH_EXPIRES_IN_KEY,
      res.refresh_token_expires_in,
      new Date(res.refresh_token_expires_in).toUTCString()
    );

    const decoded_token = JwtDecode<IJWT>(res.access_token);

    setCookie(
      COOKIE_IS_USER_DEVELOPER_KEY,
      `${!!decoded_token.user}`,
      new Date(res.refresh_token_expires_in).toUTCString()
    );

    store.dispatch("updateCurrentUser", {
      authorized: true,
      currentUser: res.user,
      currentUserPermissions: res.permissions
    });
  }

  public async authorizationRefresh() {
    this.refresh_token_in_progress = true;

    const refresh_token = getCookie(COOKIE_REFRESH_TOKEN_KEY);
    const refresh_token_expires_in = getCookie(COOKIE_REFRESH_EXPIRES_IN_KEY);

    if (!refresh_token.length) {
      this.refresh_token_in_progress = false;

      return this.redirectToAuth();
    }

    if (
      refresh_token_expires_in.length &&
      new Date(refresh_token_expires_in) > new Date()
    ) {
      const { data: res }: { data: ISignInResponse } = await this.post(
        auth_routes.refresh,
        {
          refresh_token
        }
      );

      if (res.error) {
        throw new Error(res.error);
      }

      this.setCurrentUser(res);

      if (res.user.is_lock) {
        this.refresh_token_in_progress = false;

        return this.redirectToTechnicalWork();
      }
    }

    this.refresh_token_in_progress = false;
  }

  private currentUserIsDeveloper() {
    const is_developer = getCookie(COOKIE_IS_USER_DEVELOPER_KEY);

    return is_developer.length && is_developer === "true";
  }

  private async redirectToTechnicalWork() {
    return router.push({ name: "technical_work" });
  }

  private async redirectToAuth() {
    if (["auth", "dev_auth"].includes(router.currentRoute.name!)) {
      setCookie(COOKIE_IS_USER_DEVELOPER_KEY, "true");

      return;
    }

    if (this.currentUserIsDeveloper()) {
      return router.push({ name: "auth" });
    } else {
      return this.redirectToOAuth();
    }
  }

  public async redirectToOAuth() {
    return this.get(auth_routes.login_by_openid);
  }

  private async redirectToServiceUnavailable() {
    return router.push({ name: "service_unavailable" });
  }

  public allowOnlyUserWorkplaces(route_name?: string) {
    const current_user: IJWTUser | null = store.getters.current_user;
    const meta = route_name
      ? routes_meta[route_name]
      : (router.currentRoute.meta as IRouteMeta);

    // ! Если нету текущего пользователя или роута, то только рабочие места пользователя
    if (!meta || !current_user) {
      return true;
    }

    // ! Если текущий роут не требует разрешений, то все рабочие места
    if (!meta.permissions?.length) {
      return false;
    }

    // ! Разработчик и администратор видят все рабочие места
    if (current_user.is_developer || current_user.is_admin) {
      return false;
    }

    const current_user_permissions: ISignInResponsePermissions[] | null =
      store.getters.current_user_permissions;

    // ! Если у текущего пользователя нету разрешений, то только его рабочие места
    if (!current_user_permissions?.length) {
      return true;
    }

    const route_permissions = current_user_permissions.filter(permission =>
      meta.permissions!.includes(permission.permission_id)
    );

    if (route_permissions.length) {
      return route_permissions.every(
        permission => permission.only_user_workplaces
      );
    }

    // ! Если у текущего пользователя нету разрешений, то только его рабочие места
    return true;
  }

  public allowWrite(route_name?: string) {
    const current_user: IJWTUser | null = store.getters.current_user;
    const meta = route_name
      ? routes_meta[route_name]
      : (router.currentRoute.meta as IRouteMeta);

    // ! Если нету текущего пользователя или роута, то нельзя
    if (!meta || !current_user) {
      return false;
    }

    // ! Если текущий роут не требует разрешений, то можно
    if (!meta.permissions?.length) {
      return true;
    }

    // ! Разработчик может всё
    if (current_user.is_developer) {
      return true;
    }

    // ! Администратор может всё, кроме того что только для разработчика
    if (current_user.is_admin) {
      if (meta.permissions.length === 1) {
        return meta.permissions[0] !== "developer";
      } else {
        return true;
      }
    }

    // ! Прочие пользователи могут только то, что им разрешено
    const permission_ids: TAdmissionPermission[] = [];

    const current_user_permissions: ISignInResponsePermissions[] | null =
      store.getters.current_user_write_permissions;

    if (current_user_permissions) {
      permission_ids.push(
        ...pluck<ISignInResponsePermissions, TAdmissionPermission>(
          current_user_permissions,
          "permission_id"
        )
      );
    }

    // ! Если пользователь без разрешений, то нельзя
    if (!permission_ids.length) {
      return false;
    }

    return meta.permissions.every(permission =>
      permission_ids.includes(permission)
    );
  }

  public allowRead(route_name: string) {
    const current_user: IJWTUser | null = store.getters.current_user;
    const meta = routes_meta[route_name];

    // ! Если нету текущего пользователя или роута, то нельзя
    if (!meta || !current_user) {
      return false;
    }

    // ! Если текущий роут не требует разрешений, то можно
    if (!meta.permissions?.length) {
      return true;
    }

    // ! Разработчик может всё
    if (current_user.is_developer) {
      return true;
    }

    // ! Администратор может всё, кроме того что только для разработчика
    if (current_user.is_admin) {
      if (meta.permissions.length === 1) {
        return meta.permissions[0] !== "developer";
      } else {
        return true;
      }
    }

    // ! Прочие пользователи могут только то, что им разрешено
    const permission_ids: TAdmissionPermission[] = [];

    const current_user_permissions: ISignInResponsePermissions[] | null =
      store.getters.current_user_permissions;

    if (current_user_permissions) {
      permission_ids.push(
        ...pluck<ISignInResponsePermissions, TAdmissionPermission>(
          current_user_permissions,
          "permission_id"
        )
      );
    }

    // ! Если пользователь без разрешений, то нельзя
    if (!permission_ids.length) {
      return false;
    }

    return meta.permissions.every(permission =>
      permission_ids.includes(permission)
    );
  }

  public get = async (url: string, options?: AxiosRequestConfig) => {
    return await this.http_client.get(url, {
      ...options
    });
  };

  public post = async (
    url: string,
    body: any,
    options?: AxiosRequestConfig
  ) => {
    return await this.http_client.post(url, body, {
      ...options
    });
  };

  public put = async (url: string, body: any, options?: AxiosRequestConfig) => {
    return await this.http_client.put(url, body, {
      ...options
    });
  };

  public destroy = async (url: string, options?: AxiosRequestConfig) => {
    return await this.http_client.delete(url, {
      ...options
    });
  };

  public upload = async (body: any, options?: AxiosRequestConfig) => {
    return await this.http_client.post(`${this.cdn_url}/attachments`, body, {
      ...options,
      ...{
        headers: {
          Authorization: getCookie(COOKIE_JWT_TOKEN_KEY),
          "X-Authorization": this.currentUserIsDeveloper().toString()
        }
      }
    });
  };

  public import = async (
    url: string,
    body: FormData,
    options?: AxiosRequestConfig
  ) => {
    return await this.http_client_import.post(url, body, {
      ...options
    });
  };
}

export default {
  install(Vue: typeof _Vue, _options?: any) {
    Vue.prototype.$api = new Api();
  }
};
