import { IRouteConfig } from "../interface";

export const mobile_app_routes: IRouteConfig[] = [
  {
    path: "/download_app",
    name: "download_app",
    meta: {
      name: "Приложение",
      check_jwt: false
    },
    component: () => import("../../views/download_app/DownloadApp.vue")
  },
  {
    path: "/ios",
    name: "ios",
    meta: {
      name: "iOS",
      check_jwt: false
    },
    component: () => import("../../views/download_app/IOSInstallation.vue")
  },
  {
    path: "/android",
    name: "android",
    meta: {
      name: "Android",
      check_jwt: false
    },
    component: () => import("../../views/download_app/AndroidInstallation.vue")
  }
];
