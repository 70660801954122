import {
  EAdmissionPermission,
  EAdmissionPermissionLeader
} from "@/models/admission/permission";
import { IRouteConfig } from "../interface";
import { staffs_and_groups_routes } from "./users";
import { tasks_routes } from "./tasks";
import { positions_routes } from "./positions";
import { teams_routes } from "./teams";

const employees_child_routes: IRouteConfig[] = [
  ...teams_routes,
  ...staffs_and_groups_routes,
  ...positions_routes,
  ...tasks_routes,
  {
    path: "/user_signs",
    name: "user_signs",
    meta: {
      name: "Признаки сотрудников",
      check_jwt: true,
      permissions: [EAdmissionPermission.USER_SIGNS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/user/Signs.vue")
  }
];

export const employees_routes: IRouteConfig[] = [
  {
    path: "/employees",
    name: "employees",
    meta: {
      name: "Сотрудники",
      check_jwt: true,
      permissions: [
        EAdmissionPermission.USER_SIGNS,
        EAdmissionPermissionLeader.TEAMS,
        EAdmissionPermission.USERS,
        EAdmissionPermission.POSITIONS
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Employee.vue"),
    children: employees_child_routes,
    redirect: { name: "staffs_and_groups" }
  }
];
