var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"content",attrs:{"id":"app"}},[_c('div',{staticClass:"content-inner"},[(_vm.authorized && _vm.show_navigation)?_c('Navigation',{key:`show_navigation_${_vm.navigation_updated_key}`}):_vm._e(),_c('div',{class:{
        'content-right': _vm.authorized && _vm.$route.meta?.check_jwt,
        'd-flex full-width flex-auto': !_vm.authorized || _vm.current_user?.is_lock,
        'mobile-app': _vm.authorized && !_vm.$route.meta?.check_jwt
      }},[(_vm.authorized)?[_c('ContentHeader'),_vm._l((_vm.banners),function(banner){return _c('div',{key:banner.id,staticClass:"t-6 banner-block flex-none",class:{
            'banner-block--warning': banner.is_warning,
            'banner-block--critical': banner.is_critical,
            'banner-block--info': banner.is_info
          }},[_c('div',{staticClass:"row"},[_c('p',{staticClass:"font-12 font-bold font-white b-4",domProps:{"innerHTML":_vm._s(banner.value)}})])])})]:_vm._e(),_c('router-view')],2)],1),(_vm.authorized)?[_c('CNotification')]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }