
import { Component, Vue } from "vue-property-decorator";
import { student_nav_routes } from "@/router";
import { users_routes } from "@/router/users";
import { IRouteConfig } from "@/router/interface";
import { ISignInResponsePermissions } from "@/api/routes/auth";

@Component({ name: "CContentHeader" })
export default class ContentHeader extends Vue {
  private current_user: IJWTUser | null = this.$store.state.currentUser;
  private current_user_permissions: ISignInResponsePermissions[] | null = this
    .$store.state.currentUserPermissions;

  public download_qr = `/img/qr/qr-code-${
    this.$api.environment === "development" ? "beta" : this.$api.environment
  }.png`;

  public notification_link?: IRouteConfig | null = null;
  public download_link?: IRouteConfig | null = null;
  public dashboard_link?: IRouteConfig | null = null;
  public profile_link?: IRouteConfig | null = null;

  public countNotification: number | null = this.$store.state.countNotification;

  public profile_active = false;

  public window_width = window.innerWidth;

  public show_navigation = false;

  protected watchers: Function[] = [];

  protected created() {
    this.notification_link = users_routes.find(
      child => child.name === "me_notifications"
    );

    this.download_link = users_routes.find(child => child.name === "download");

    this.dashboard_link = users_routes.find(
      child => child.name === "me_dashboard"
    );

    this.profile_link = users_routes.find(child => child.name === "me_profile");

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;

          this.showNavigation();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUserPermissions,
        currentUserPermissions => {
          this.current_user_permissions = currentUserPermissions;

          this.showNavigation();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.countNotification,
        countNotification => {
          this.countNotification = countNotification;
        }
      )
    );

    this.showNavigation();
  }

  private showNavigation() {
    this.show_navigation =
      !this.current_user_permissions?.length &&
      !this.current_user?.is_admin &&
      !this.current_user?.is_developer;
  }

  public get filtered_routes() {
    return this.window_width < 1024
      ? [student_nav_routes?.[0]]
      : student_nav_routes;
  }

  public handleResize() {
    this.window_width = window.innerWidth;
  }

  protected mounted() {
    window.addEventListener("resize", this.handleResize);
  }

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });

    window.removeEventListener("resize", this.handleResize);
  }

  public toggleProfile() {
    this.profile_active = !this.profile_active;
  }

  public async signOut() {
    return this.$api.unauthorize();
  }
}
