import {
  EAdmissionPermission,
  EAdmissionPermissionLeader
} from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

export const analytics_leader_routes: IRouteConfig[] = [
  {
    path: "skill_categories",
    name: "skill_categories",
    props: true,
    meta: {
      name: "Тип навыков",
      check_jwt: true,
      permissions: [EAdmissionPermissionLeader.DASHBOARDS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/dashboards/SkillCategoryChart.vue"
      )
  },
  {
    path: "skill_levels",
    name: "skill_levels",
    props: true,
    meta: {
      name: "Уровень Т0-Т4",
      check_jwt: true,
      permissions: [EAdmissionPermissionLeader.DASHBOARDS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/dashboards/SkillLevelChart.vue"
      )
  }
];

const analytics_child_routes: IRouteConfig[] = [
  {
    path: "/analytics",
    name: "analytics",
    props: true,
    meta: {
      name: "Аналитика",
      check_jwt: true,
      permissions: [EAdmissionPermission.ANALYTICS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/analytics/Analytics.vue"
      )
  },
  {
    path: "/supervisor_dashboard",
    name: "supervisor_dashboard",
    props: true,
    meta: {
      name: "Дашборд руководителя",
      check_jwt: true,
      permissions: [EAdmissionPermissionLeader.DASHBOARDS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Dashboards.vue"),
    children: analytics_leader_routes,
    redirect: { name: "skill_categories" }
  }
];

export const analytics_routes: IRouteConfig[] = [
  {
    path: "/analytical",
    name: "analytical",
    meta: {
      name: "Аналитика",
      check_jwt: true,
      permissions: [
        EAdmissionPermission.ANALYTICS,
        EAdmissionPermissionLeader.DASHBOARDS
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Organization.vue"),
    children: analytics_child_routes,
    redirect: { name: "analytics" }
  }
];
