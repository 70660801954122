import { IRouteConfig } from "../interface";

export const examinations_child_list_routes: IRouteConfig[] = [
  {
    path: "/me/examination/akt",
    name: "me_akt",
    meta: {
      name: "ЕПЗ",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user/examination/AktTestings.vue"
      )
  },
  {
    path: "/me/examination/testings",
    name: "me_testings",
    meta: {
      name: "Тесты",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user/examination/Testings.vue"
      )
  },
  {
    path: "/me/examination/tasks",
    name: "me_tasks",
    props: true,
    meta: {
      name: "Практические/Индивидуальные задания",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user/examination/Tasks.vue"
      )
  }
];

const examinations_child_routes: IRouteConfig[] = [
  ...examinations_child_list_routes,
  {
    path: "/me/examination/tasks/:task_id",
    name: "examination_task",
    props: true,
    meta: {
      name: "Практические/Индивидуальные задания",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user/examination/Task.vue"
      )
  },
  {
    path: "/me/akt/:id",
    name: "akt_user_testing",
    props: true,
    meta: {
      name: "Прохождение ЕПЗ",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user/akt_testing/Testing.vue"
      )
  },
  {
    path: "/me/pass_testing/:id",
    name: "user_pass_testing",
    props: true,
    meta: {
      name: "Прохождение Тестирования",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user/testing/Testing.vue"
      )
  }
];

export const examinations_routes: IRouteConfig[] = [
  {
    path: "/me/examination",
    name: "me_examination",
    meta: {
      name: "Проверка знаний",
      check_jwt: true
    },
    redirect: { name: "me_akt" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Examination.vue"),
    children: examinations_child_routes
  }
];
