import { IRouteConfig } from "../interface";

export const kpi_settings_child_routes: IRouteConfig[] = [
  {
    path: "/kpi/settings/params",
    name: "kpi_setting_params",
    meta: {
      name: "KPI Параметры",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/kpi/Setting.vue")
  },
  {
    path: "/kpi/settings/users",
    name: "kpi_setting_users",
    meta: {
      name: "KPI Настройка прав",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/kpi/SettingUser.vue")
  }
];

export const kpi_routes: IRouteConfig[] = [
  {
    path: "/kpi/all",
    name: "kpi_all",
    meta: {
      name: "KPI Цели (ЕОД)",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/kpi/All.vue")
  },
  {
    path: "/kpi/catalog",
    name: "kpi_catalog",
    meta: {
      name: "KPI Справочник",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/kpi/Catalog.vue")
  },
  {
    path: "/kpi/settings",
    name: "kpi_settings",
    meta: {
      name: "KPI Настройки",
      check_jwt: true,
      permissions: ["admin"]
    },
    children: kpi_settings_child_routes,
    redirect: { name: "kpi_setting_params" }
  },
  {
    path: "/kpi/assignments",
    name: "kpi_assignments",
    meta: {
      name: "KPI Назначение",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/kpi/Assignment.vue")
  },
  {
    path: "/kpi/archive",
    name: "kpi_archive",
    meta: {
      name: "KPI Архив",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/kpi/Archive.vue")
  },
  {
    path: "/kpi/reports",
    name: "kpi_report",
    props: true,
    meta: {
      name: "KPI Распоряжение",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/kpi/Report.vue")
  }
];
