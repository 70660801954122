export function normalizedSubscriptionStatus(status: number) {
  switch (status) {
    case 1:
      return "Не начат";
    case 2:
      return "В процессе выполнения";
    case 3:
      return "Выполнен корректно";
    case 4:
      return "Выполнен некорректно";
    case 5:
      return "Ожидает подтверждения";
    case 6:
      return "Ожидает перепрохождения";
    case 7:
      return "Ожидает продолжения обучения";
    case 8:
      return "Ожидает завершения программы";
    default:
      "";
  }
}

export function normalizedSubscriptionInspectorStatus(status: number) {
  switch (status) {
    case 1:
      return "Ожидание";
    case 2:
      return "Отклонено";
    case 3:
      return "Подтверждено";
    default:
      "";
  }
}

export function normalizedSubscriptionLevel(status: number) {
  switch (status) {
    case 1:
      return "Новичок - не использует знания на практике";
    case 2:
      return "Специалист - применяет знания на практике под контролем";
    case 3:
      return "Профессионал - работает самостоятельно";
    case 4:
      return "Гуру - профессионал, способен обучать других";
    default:
      "";
  }
}
