import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

export const staffs_and_groups_child_routes: IRouteConfig[] = [
  {
    path: "staffs",
    name: "staffs",
    meta: {
      name: "Список сотрудников",
      check_jwt: true,
      permissions: [EAdmissionPermission.USERS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/staffs_and_groups/staffs/All.vue"
      )
  },
  {
    path: "staffs_tree",
    name: "staffs_tree",
    meta: {
      name: "По рабочим местам",
      check_jwt: true,
      permissions: [EAdmissionPermission.USERS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/staffs_and_groups/staffs/Tree.vue"
      )
  }
];

export const staffs_and_groups_routes: IRouteConfig[] = [
  {
    path: "/staffs_and_groups",
    name: "staffs_and_groups",
    meta: {
      name: "Сотрудники",
      check_jwt: true,
      permissions: [EAdmissionPermission.USERS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/StaffsAndGroups.vue"),
    children: staffs_and_groups_child_routes,
    redirect: { name: "staffs" }
  }
];
