import { ISignInResponsePermissions } from "@/api/routes/auth";
import { pluck } from "@/helpers";

import { INavRouteConfig, IRouteConfig, IRouteMeta } from "./interface";

export function getRoutesMeta(routes: IRouteConfig[]) {
  let obj: Record<string, IRouteMeta> = {};

  routes.forEach(route => {
    obj[route.name] = route.meta;

    if (route.children?.length) {
      obj = { ...obj, ...getRoutesMeta(route.children) };
    }
  });

  return obj;
}

export function checkingRoute(
  meta: IRouteMeta,
  current_user: IJWTUser,
  current_user_permissions: ISignInResponsePermissions[]
) {
  if (meta.permissions) {
    /**
     * ! Разработчик видит всё;
     * ! Администратор видит всё, кроме того что только для разработчика;
     * ! Остальные могут видеть только то что им разрешено;
     */

    const permission_ids = pluck(current_user_permissions, "permission_id");

    if (current_user.is_developer) {
      return true;
    }

    if (current_user.is_admin) {
      /**
       * ! Несколько разрешений могут быть только, если есть саброуты
       * ! Во всех остальных случаях это всегда должен быть массив из одного разрешения
       */

      if (meta.permissions.length === 1) {
        return meta.permissions[0] !== "developer";
      } else {
        return true;
      }
    }

    return meta.permissions.some(permission_id =>
      permission_ids.includes(permission_id)
    );
  }

  return true;
}

export function parseAndCheckNavRoutes(
  routes: INavRouteConfig[],
  current_user: IJWTUser,
  current_user_permissions: ISignInResponsePermissions[]
) {
  const allowed_routes: INavRouteConfig[] = [];

  routes.forEach(route => {
    if (checkingRoute(route.meta, current_user, current_user_permissions)) {
      if (route.children) {
        const allowed_children_routes = parseAndCheckNavRoutes(
          route.children,
          current_user,
          current_user_permissions
        );

        if (allowed_children_routes.length) {
          route.children = allowed_children_routes;

          allowed_routes.push(route);
        }
      } else {
        allowed_routes.push(route);
      }
    }
  });

  return allowed_routes;
}
