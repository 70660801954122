import { IRouteConfig } from "../interface";

const groups_child_routes: IRouteConfig[] = [
  {
    path: "",
    name: "groups_all",
    meta: {
      name: "Группы",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/staffs_and_groups/groups/All.vue"
      )
  },
  {
    path: ":id",
    name: "groups_one",
    props: true,
    meta: {
      name: "Группа",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/staffs_and_groups/groups/One.vue"
      )
  }
];

export const groups_routes: IRouteConfig[] = [
  {
    name: "groups",
    path: "groups",
    meta: {
      name: "Группы",
      check_jwt: true,
      permissions: ["admin"]
    },
    redirect: { name: "groups_all" },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/staffs_and_groups/Groups.vue"
      ),
    children: groups_child_routes
  }
];
