import { IRouteConfig } from "../interface";
import { examinations_routes } from "./examinations";
import { subscriptions_routes } from "./subscriptions";

export const users_routes: IRouteConfig[] = [
  {
    path: "/me/notifications",
    name: "me_notifications",
    meta: {
      name: "Уведомления",
      check_jwt: true,
      badge: 0
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/notifications/Notifications.vue"
      )
  },
  {
    path: "/download",
    name: "download",
    meta: {
      name: "Скачать  моб. приложение",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/download_app/DownloadAppWeb.vue"
      )
  },
  {
    path: "/me/notifications/:notification_id",
    name: "redirected_from_email",
    props: true,
    meta: {
      name: "Уведомления",
      check_jwt: true,
      badge: 0
    },

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/notifications/Notifications.vue"
      )
  },
  {
    path: "/me/dashboard",
    name: "me_dashboard",
    meta: {
      name: "Главная",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/user/NewDashboard.vue"
      )
  },
  {
    path: "/me/calendar",
    name: "calendar",
    meta: {
      name: "Календарь",
      check_jwt: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Calendar.vue")
  },
  {
    path: "/me/skills",
    name: "me_skills",
    props: true,
    meta: {
      name: "Мои навыки",
      check_jwt: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/user/Skill.vue")
  },
  {
    path: "/me/catalog",
    name: "me_catalog",
    meta: {
      name: "План обучения",
      check_jwt: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/user/Catalog.vue")
  },
  {
    path: "/me/profile",
    name: "me_profile",
    meta: {
      name: "Профиль",
      check_jwt: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/user/Profile.vue")
  },
  ...subscriptions_routes,
  ...examinations_routes
];
