import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

const tasks_child_routes: IRouteConfig[] = [
  {
    path: "",
    name: "task_all",
    meta: {
      name: "Проверка результатов",
      check_jwt: true,
      permissions: [EAdmissionPermission.USERS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/tasks/All.vue")
  },
  {
    path: ":task_id",
    name: "verify_task",
    props: true,
    meta: {
      name: "Задание",
      check_jwt: true,
      permissions: [EAdmissionPermission.USERS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/tasks/Task.vue")
  }
];

export const tasks_routes: IRouteConfig[] = [
  {
    path: "/tasks",
    name: "responsible_task",
    props: true,
    meta: {
      name: "Индивидуальные задания",
      check_jwt: true,
      permissions: [EAdmissionPermission.USERS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Tasks.vue"),
    children: tasks_child_routes,
    redirect: { name: "task_all" }
  }
];
