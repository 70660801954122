
import { Component, Vue } from "vue-property-decorator";
import { plainToClass } from "class-transformer";

import UAParser from "ua-parser-js";

import ContentHeader from "@/components/Header.vue";
import Navigation from "@/components/Navigation.vue";
import CNotification from "@/components/Notification.vue";

import settingsRoutes from "@/api/routes/settings";

import { EDeviceTypes } from "@/enums/deviceTypes";
import SettingsBanner from "@/models/settings/banner";
import { ISignInResponsePermissions } from "./api/routes/auth";

@Component({
  components: {
    ContentHeader,
    Navigation,
    CNotification
  }
})
export default class App extends Vue {
  public authorized = this.$store.state.authorized;
  public current_user: IJWTUser | null = this.$store.state.currentUser;

  private current_user_permissions: ISignInResponsePermissions[] | null = this
    .$store.state.currentUserPermissions;

  public banners: SettingsBanner[] = [];
  public navigation_updated_key: number = 0;

  private watchers: Function[] = [];

  public show_navigation = false;

  protected beforeDestroy() {
    this.watchers.forEach(unwatch => {
      unwatch();
    });
  }

  protected async created() {
    this.watchers.push(
      this.$store.watch(
        state => state.authorized,
        authorized => {
          this.authorized = authorized;

          this.showNavigation();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUser,
        currentUser => {
          this.current_user = currentUser;

          this.showNavigation();
        }
      )
    );

    this.watchers.push(
      this.$store.watch(
        state => state.currentUserPermissions,
        currentUserPermissions => {
          this.current_user_permissions = currentUserPermissions;

          this.showNavigation();
        }
      )
    );

    this.$store.commit("deviceType", this.howDeviceType());

    await this.loadBanners();

    this.showNavigation();
  }

  private showNavigation() {
    this.navigation_updated_key++;

    this.show_navigation =
      (!!this.current_user_permissions?.length ||
        !!this.current_user?.is_admin ||
        !!this.current_user?.is_developer) &&
      !this.current_user?.is_lock;
  }

  private async loadBanners() {
    await this.$api
      .get(settingsRoutes.available_banners)
      .then(({ data: res }: { data: SettingsBanner[] }) => {
        this.banners = plainToClass(SettingsBanner, res);
      })
      .catch(({ response: res }) => {
        this.$notify({
          group: "notifications",
          type: "error",
          text: res.data.error,
          speed: 500
        });
      });
  }

  private howDeviceType() {
    const parser = new UAParser();

    switch (parser.getDevice().type) {
      case "mobile":
        return EDeviceTypes.phone;
      case "tablet":
        return EDeviceTypes.tablet;
      default:
        return EDeviceTypes.desktop;
    }
  }
}
