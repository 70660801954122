import { IRouteConfig } from "../interface";

export const errors_routes: IRouteConfig[] = [
  {
    path: "/technical_work",
    name: "technical_work",
    meta: {
      name: "Ведутся технические работы",
      check_jwt: false
    },
    component: () => import("../../views/errors/TechnicalWork.vue")
  },
  {
    path: "/service_unavailable",
    name: "service_unavailable",
    meta: {
      name: "service_unavailable",
      check_jwt: false
    },
    component: () => import("../../views/errors/ServiceUnavailable.vue")
  },
  {
    path: "*",
    name: "unknown",
    meta: {
      name: "unknown",
      check_jwt: false
    },
    component: () => import("../../views/errors/NotFound.vue")
  }
];
