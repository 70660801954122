import { IRouteConfig } from "../interface";

const subscriptions_child_routes: IRouteConfig[] = [
  {
    path: "programs/:id",
    name: "subscription_program",
    props: true,
    meta: {
      name: "Подписка на программу",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/subscriptions/Program.vue"
      )
  },
  {
    path: "programs/:id/units/:unit_id",
    name: "subscription_unit",
    props: true,
    meta: {
      name: "Подписка на юнит",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/subscriptions/Unit.vue"
      )
  },
  {
    path: "programs/:id/units/:unit_id/calendar",
    name: "trainer_calendar",
    props: true,
    meta: {
      name: "Календарь тренера",
      check_jwt: true
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/subscriptions/Calendar.vue"
      )
  }
];

export const subscriptions_routes: IRouteConfig[] = [
  {
    path: "/subscriptions",
    name: "subscriptions",
    meta: {
      name: "Подписки",
      check_jwt: true
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Subscriptions.vue"),
    children: subscriptions_child_routes
  }
];
