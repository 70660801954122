import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

const constructor_child_routes: IRouteConfig[] = [
  {
    path: "",
    name: "constructor_programs_index",
    meta: {
      name: "Программы",
      check_jwt: true,
      permissions: [EAdmissionPermission.EDUCATION_PROGRAMS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/constructor/programs/Index.vue"
      )
  },
  {
    path: "factories/:id/new",
    name: "constructor_programs_new",
    props: true,
    meta: {
      name: "Программа",
      check_jwt: true,
      permissions: [EAdmissionPermission.EDUCATION_PROGRAMS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/constructor/programs/New.vue"
      )
  },
  {
    path: "programs/:id/edit",
    name: "constructor_programs_edit",
    props: true,
    meta: {
      name: "Программа",
      check_jwt: true,
      permissions: [EAdmissionPermission.EDUCATION_PROGRAMS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/constructor/programs/Edit.vue"
      )
  }
];

export const programs_routes: IRouteConfig[] = [
  {
    path: "/constructor",
    name: "constructor",
    meta: {
      name: "Программы обучения",
      check_jwt: true,
      permissions: [EAdmissionPermission.EDUCATION_PROGRAMS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Constructor.vue"),
    children: constructor_child_routes,
    redirect: { name: "constructor_programs_index" }
  }
];
