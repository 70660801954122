import { IRouteConfig } from "../interface";

export const auth_routes: IRouteConfig[] = [
  {
    path: "/dev_auth",
    name: "dev_auth",
    meta: {
      name: "Авторизация",
      check_jwt: false
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Auth.vue")
  },
  {
    path: "/auth/callback_digital_library",
    name: "auth_callback",
    meta: {
      name: "OpenID Connect авторизация",
      check_jwt: false
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/AuthCallback.vue")
  },
  {
    path: "/auth",
    name: "auth",
    meta: {
      name: "Авторизация",
      check_jwt: false
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/AuthMiddle.vue")
  }
];
