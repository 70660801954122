import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

const skills_child_routes: IRouteConfig[] = [
  {
    path: "",
    name: "skills_all",
    meta: {
      name: "Навыки",
      check_jwt: true,
      permissions: [EAdmissionPermission.SKILLS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/skills/All.vue")
  }
];

export const skills_routes: IRouteConfig[] = [
  {
    path: "/skills",
    name: "skills",
    meta: {
      name: "Навыки",
      check_jwt: true,
      permissions: [EAdmissionPermission.SKILLS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Skills.vue"),
    children: skills_child_routes,
    redirect: { name: "skills_all" }
  },
  {
    path: "/skill_signs",
    name: "skill_signs",
    meta: {
      name: "Признаки навыков",
      check_jwt: true,
      permissions: [EAdmissionPermission.SKILL_SIGNS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/SkillSigns.vue")
  },
  {
    path: "/inventories",
    name: "inventories",
    meta: {
      name: "Группы оборудования",
      check_jwt: true,
      permissions: [EAdmissionPermission.INVENTORIES]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Inventories.vue")
  },
  {
    path: "/priorities",
    name: "priorities",
    meta: {
      name: "Приоритеты",
      check_jwt: true,
      permissions: [EAdmissionPermission.PRIORITIES]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Priorities.vue")
  },
  {
    path: "/local_block",
    name: "local_block",
    meta: {
      name: "Группы навыков",
      check_jwt: true,
      permissions: [EAdmissionPermission.BLOCKS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/LocalBlocks.vue")
  }
];
