import { IRouteConfig } from "../interface";

export const compulsory_educations_routes: IRouteConfig[] = [
  {
    path: "/training_planning",
    name: "training_planning",
    meta: {
      name: "CE Планирование обучения",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/TrainingPlanning.vue")
  },
  {
    path: "/training_applications",
    name: "training_applications",
    props: true,
    meta: {
      name: "CE Заявки на обучение",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/TrainingApplications.vue"
      )
  },
  {
    path: "/appoint_responsible",
    name: "appoint_responsible",
    meta: {
      name: "CE Ответственные",
      check_jwt: true,
      permissions: ["admin"]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/AppointResponsible.vue"
      )
  }
];
