import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

export const positions_routes: IRouteConfig[] = [
  {
    path: "/positions",
    name: "positions",
    meta: {
      name: "Должности",
      check_jwt: true,
      permissions: [EAdmissionPermission.POSITIONS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/positions/Positions.vue"
      )
  },
  {
    path: "/position_levels",
    name: "position_levels",
    meta: {
      name: "Уровни развития",
      check_jwt: true,
      permissions: [EAdmissionPermission.POSITIONS]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/positions/LevelTree.vue"
      )
  }
];
