import { EAdmissionPermission } from "@/models/admission/permission";
import { IRouteConfig } from "../interface";

const akt_child_routes: IRouteConfig[] = [
  {
    path: "questions",
    name: "akt_questions",
    props: true,
    meta: {
      name: "Вопросы ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_QUESTIONS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/akt/Questions.vue")
  },
  {
    path: "templates",
    name: "akt_templates",
    meta: {
      name: "Шаблоны ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_TEMPLATES]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/akt/Templates.vue")
  },
  {
    path: "assignments",
    name: "akt_assignments",
    meta: {
      name: "Настройки ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_SETTINGS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/akt/Assignments.vue")
  },
  {
    path: "testings",
    name: "akt_passed_testing",
    meta: {
      name: "Завершенные ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_COMPLETED]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/akt/PassedTestings.vue"
      )
  },
  {
    path: "/akt/templates/new",
    name: "akt_templates_new",
    props: true,
    meta: {
      name: "Новый шаблон ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_TEMPLATES]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/akt/template/New.vue")
  },
  {
    path: "/akt/templates/:id",
    name: "akt_templates_edit",
    props: true,
    meta: {
      name: "Редактировать шаблон ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_TEMPLATES]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/akt/template/Edit.vue"
      )
  },
  {
    path: "/akt/testings/:id",
    name: "akt_testing",
    props: true,
    meta: {
      name: "Результаты ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_COMPLETED]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/akt/testings/Testing.vue"
      )
  },
  {
    path: "/akt/testings/:id/source",
    name: "akt_testing_task",
    props: true,
    meta: {
      name: "Печать задания ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_COMPLETED]
    },
    component: () =>
      import(
        /* webpackChunkName: "about" */ "../../views/akt/testings/Task.vue"
      )
  },
  {
    path: "/akt/protocols",
    name: "akt_protocol",
    props: true,
    meta: {
      name: "Групповой протокол",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_COMPLETED]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/akt/Protocol.vue")
  },
  {
    path: "/akt/questions/imports",
    name: "akt_import",
    meta: {
      name: "Список импортов вопросов ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_QUESTIONS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/akt/Imports.vue")
  },
  {
    path: "/akt/questions/imports/:id",
    name: "akt_import_question",
    props: true,
    meta: {
      name: "Импорт вопросов ЕПЗ",
      check_jwt: true,
      permissions: [EAdmissionPermission.AKT_QUESTIONS]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/akt/imports/Edit.vue")
  }
];

export const akt_routes: IRouteConfig[] = [
  {
    path: "/akt",
    name: "akt",
    meta: {
      name: "Настройки ЕПЗ",
      check_jwt: true,
      permissions: [
        EAdmissionPermission.AKT_TEMPLATES,
        EAdmissionPermission.AKT_QUESTIONS,
        EAdmissionPermission.AKT_SETTINGS,
        EAdmissionPermission.AKT_COMPLETED
      ]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Akt.vue"),
    children: akt_child_routes,
    redirect: { name: "akt_questions" }
  }
];
