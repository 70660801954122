import { IRouteConfig } from "../interface";

const developer_child_routes: IRouteConfig[] = [
  {
    path: "/banner_settings",
    name: "banner_settings",
    meta: {
      name: "Настройки баннера",
      check_jwt: true,
      permissions: ["developer"]
    },
    component: () => import("../../views/settings/Banners.vue")
  },
  {
    path: "/imported_files",
    name: "imported_files",
    meta: {
      name: "Отчеты по импорту",
      check_jwt: true,
      permissions: ["developer"]
    },
    component: () => import("../../views/settings/Import.vue")
  },
  {
    path: "/mobile_app_versions",
    name: "mobile_app_versions",
    meta: {
      name: "Загрузка приложения",
      check_jwt: true,
      permissions: ["developer"]
    },
    component: () => import("../../views/settings/MobileAppVersions.vue")
  },
  {
    path: "/mobile_logs",
    name: "mobile_logs",
    meta: {
      name: "Логи мобильного приложения",
      check_jwt: true,
      permissions: ["developer"]
    },
    component: () => import("../../views/settings/Logs.vue")
  }
];

/** Отображается в навигационном меню */
export const developer_routes: IRouteConfig[] = [
  {
    path: "/developer",
    name: "developer",
    meta: {
      name: "Настройки разработчика",
      check_jwt: true,
      permissions: ["developer"]
    },
    component: () =>
      import(/* webpackChunkName: "about" */ "../../views/Settings.vue"),
    children: developer_child_routes,
    redirect: { name: "banner_settings" }
  }
];
